import React from "react";
import styled from "styled-components";

import { HoverLink, Text, NotForPrint, HoverLinkButton } from "@envato/eds";
import { useExternalLink } from "@envato/sso-common";

import LocaleSwitcher from "../LocaleSwitcher";

const Container = styled.footer`
  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.bgPrimary};

  padding: ${(props) => props.theme.spacing2x}
    ${(props) => props.theme.spacing3x};

  border-top: 1px solid ${(props) => props.theme.colorDivider};

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    padding: ${(props) => props.theme.spacing3x};

    height: ${(props) => props.theme.heightFooterLarge};
  }
`;

const FooterLinks = styled.ul`
  display: flex;
  flex: 1;
  align-items: flex-start;
  padding: 0;
  flex-direction: column;

  > * {
    margin-bottom: ${(props) => props.theme.spacing3x};

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    flex-direction: row;
    justify-content: center;

    > * {
      margin-bottom: 0;
    }
  }
`;

const FooterLinkItem = styled.li`
  display: flex;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    padding: 0 ${(props) => props.theme.spacing4x};
  }
`;

const FooterLink = styled(HoverLink)``;

const FooterLinkButton = styled(HoverLinkButton)``;

const FooterLinkText = styled(Text).attrs({
  variant: "body-small",
  color: "secondary",
})``;

type FooterLinkProps = {
  to: string;
  onClick: () => void;
};

interface FooterLink {
  LinkComponent: React.FC<Partial<FooterLinkProps>>;
  props: Partial<FooterLinkProps>;
  text: string;
}

const Footer: React.FC = () => {
  const footerLinks: FooterLink[] = [
    {
      text: "footer.help",
      LinkComponent: FooterLink,
      props: {
        to: useExternalLink("marketHelp"),
      },
    },
    {
      text: "footer.about",
      LinkComponent: FooterLink,
      props: {
        to: useExternalLink("envatoAbout"),
      },
    },
    {
      text: "footer.privacy",
      LinkComponent: FooterLink,
      props: {
        to: useExternalLink("envatoPrivacyPolicy"),
      },
    },
    {
      text: "footer.cookies",
      LinkComponent: FooterLink,
      props: {
        to: useExternalLink("envatoCookies"),
      },
    },
    {
      text: "footer.cookieSettings",
      LinkComponent: FooterLinkButton,
      props: {
        onClick: () => {
          if (window.Cookiebot) {
            window.addEventListener("CookiebotOnLoad", () =>
              window.location.reload(),
            );
            window.Cookiebot.renew();
          }

          return false;
        },
      },
    },
    {
      text: "footer.personalInformation",
      LinkComponent: FooterLink,
      props: {
        to: useExternalLink("envatoPersonalInformation"),
      },
    },
  ];

  return (
    <NotForPrint>
      <Container>
        <FooterLinks>
          {footerLinks.map(({ text, LinkComponent, props }) => {
            return (
              <FooterLinkItem key={text}>
                <LinkComponent {...props}>
                  <FooterLinkText text={text} />
                </LinkComponent>
              </FooterLinkItem>
            );
          })}
          <FooterLinkItem>
            <FooterLinkText>
              <LocaleSwitcher />
            </FooterLinkText>
          </FooterLinkItem>
        </FooterLinks>
      </Container>
    </NotForPrint>
  );
};

export default Footer;
