import React, { useCallback, useState } from "react";
import styled from "styled-components";

import * as Analytics from "@envato/sso-analytics";
import { Stack, Box, Text, Link, ContextButton } from "@envato/eds";
import { useLanguageCode } from "@envato/sso-common";

import localeImage from "../../assets/images/locale-light.svg";
import toggleImage from "../../assets/images/arrow-up.svg";
import { localisedPath } from "../../utils/i18n";

const Container = styled.div`
  position: relative;
`;

const Icon = styled.img.attrs({
  src: localeImage,
})``;

const SwitcherText = styled(Text).attrs({
  variant: "body-small",
  color: "secondary",
})``;

const ToggleButton = styled(ContextButton)`
  cursor: pointer;
  border: 0;
  background: none;
  appearance: none;

  display: flex;
  align-items: center;

  > * {
    margin-right: ${(props) => props.theme.spacing2x};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ToggleImage = styled.img.attrs({
  src: toggleImage,
})<{ isOpen: boolean }>`
  transition: all ${(props) => props.theme.transitionDurationShort}
    ${(props) => props.theme.transitionEasingLinear};
  transform: rotate(180deg);
  ${(props) => props.isOpen && "transform: rotate(0deg);"}
`;

const locales: { [key: string]: string } = {
  de: "Deutsch",
  en: "English",
  es: "Español",
  fr: "Français",
  "pt-BR": "Português brasileiro",
  ru: "Pусский",
};

const Mask = styled.div`
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const LocaleOptionsContainer = styled(Box).attrs({
  padding: "3x",
})`
  position: absolute;
  left: 0;
  margin: 0;
  bottom: ${(props) => props.theme.spacing4x};
  border-radius: ${(props) => props.theme.radiusBase};
  background-color: ${(props) => props.theme.bgPrimary};
  box-shadow: ${(props) => props.theme.shadowLevel1};

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    left: initial;
    right: 0;
  }
`;

const LocaleOptionsList = styled(Stack).attrs({
  spacing: "3x",
  as: "ul",
})`
  list-style: none;
  padding: 0;
`;

const LocaleOptionsItem = styled(Text).attrs({
  forwardedAs: "li",
  variant: "body-small",
})``;

const LocaleOptions = () => (
  <LocaleOptionsContainer>
    <LocaleOptionsList>
      {Object.keys(locales).map((selected) => (
        <LocaleOptionsItem key={selected}>
          <Link to={localisedPath(window.location, selected as LanguageCode)}>
            {locales[selected]}
          </Link>
        </LocaleOptionsItem>
      ))}
    </LocaleOptionsList>
  </LocaleOptionsContainer>
);

const View = () => {
  const [showOptions, setShowOptions] = useState(false);
  const currentLocale = useLanguageCode();

  const toggleOptions = useCallback(() => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  return (
    <Container>
      {showOptions && <Mask onClick={toggleOptions} />}
      {showOptions && <LocaleOptions />}
      <Analytics.Element
        trackingId="LocaleSwitcher"
        trackingLabel={showOptions ? "hide" : "show"}
      >
        <ToggleButton onClick={toggleOptions}>
          <Icon />
          <SwitcherText>{locales[currentLocale]}</SwitcherText>
          <ToggleImage isOpen={showOptions} />
        </ToggleButton>
      </Analytics.Element>
    </Container>
  );
};

export default View;
