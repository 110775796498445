import React from "react";
import styled from "styled-components";

import { useAuthTracking } from "@envato/sso-analytics";
import {
  DefaultButtonLink,
  Inline,
  Link,
  NotForPrint,
  Hidden,
  boxShadowBorder,
} from "@envato/eds";

import { useConfig, useLocalisedPath, useClient } from "@envato/sso-common";

import clientLogo from "../../utils/clientLogo";

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: ${(props) => props.theme.heightTopbar};

  background-color: ${(props) => props.theme.bgTopbar};

  border-bottom: 1px solid ${(props) => props.theme.colorDivider};

  padding: ${(props) => props.theme.spacing3x}
    ${(props) => props.theme.spacing3x};

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    padding: ${(props) => props.theme.spacing3x}
      ${(props) => props.theme.spacing4x};
  }
`;

export const TopbarButton = styled(DefaultButtonLink).attrs({
  size: "medium",
})`
  background-color: ${(props) => props.theme.btnTopbarActive};
  font-family: ${(props) => props.theme.fontTopbarButton};
  color: ${(props) => props.theme.btnTopbarActiveText};
  ${(props) =>
    boxShadowBorder(
      props.theme.activeBorderWidth,
      props.theme.btnTopbarActiveBorder,
    )}

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.btnTopbarHover};
    color: ${(props) => props.theme.btnTopbarHoverText};

    ${(props) =>
      boxShadowBorder(
        props.theme.focusBorderWidth,
        props.theme.btnTopbarHoverBorder,
      )}
  }

  &:focus {
    background-color: ${(props) => props.theme.btnTopbarFocus};
    color: ${(props) => props.theme.btnTopbarFocusText};

    ${(props) =>
      boxShadowBorder(
        props.theme.focusBorderWidth,
        props.theme.btnTopbarFocusBorder,
      )}
  }
`;

const Logo = styled.img.attrs({
  alt: "Envato Logo",
})`
  min-height: ${(props) => props.theme.heightTopbar};
  width: ${(props) => props.theme.widthLogoLarge};
`;

const TopbarLink = styled(Link)`
  display: flex;
`;
export interface TopbarProps {
  showSignIn?: boolean;
  showSignUp?: boolean;
  children?: React.ReactNode;
}

const Topbar: React.FC<TopbarProps> = ({
  showSignIn = false,
  showSignUp = false,
  children,
}) => {
  const client = useClient();

  const topbarLogo = clientLogo(client?.appId);

  const [{ currentUser }] = useConfig();

  const signInLink = useLocalisedPath("signIn");
  const signUpLink = useLocalisedPath("signUp");
  const signOutLink = useLocalisedPath("signOut");
  const adminLink = useLocalisedPath("admin");
  const sidekiqLink = useLocalisedPath("sidekiqWeb");
  const homeLink = useLocalisedPath("home");
  const handleAuthEvent = useAuthTracking();

  return (
    <Container>
      <TopbarLink to={homeLink}>
        <Logo src={topbarLogo} />
      </TopbarLink>
      <NotForPrint>
        <Inline spacing="2x">
          {currentUser?.canViewSidekiq && (
            <Hidden below="tablet">
              <TopbarButton
                text="topbar.sidekiq"
                to={sidekiqLink}
                testId="topbarSidekiqButton"
              />
            </Hidden>
          )}
          {currentUser?.admin && (
            <TopbarButton
              text="topbar.admin"
              to={adminLink}
              testId="topbarAdminButton"
            />
          )}
          {currentUser && (
            <TopbarButton
              text="topbar.signOut"
              onClick={() => {
                handleAuthEvent({ type: "auth", eventName: "sign_out" });
              }}
              to={signOutLink}
              testId="topbarSignOutButton"
            />
          )}
          {showSignIn && (
            <TopbarButton
              text="topbar.signIn"
              to={signInLink}
              testId="topbarSignInButton"
            />
          )}
          {showSignUp && (
            <TopbarButton
              text="topbar.signUp"
              to={signUpLink}
              testId="topbarSignUpButton"
            />
          )}

          {children}
        </Inline>
      </NotForPrint>
    </Container>
  );
};

export default Topbar;
