// This is included to overcome a babel polyfill issue - see https://stackoverflow.com/a/54490329/4548375
import "regenerator-runtime/runtime";

import React from "react";
import { Provider as JotaiProvider } from "jotai";

import {
  ConfigValues,
  withConfig,
  withI18N,
  withTheme,
  withBrandNeue,
  withTracking,
} from "@envato/sso-common";

import withCookiebot from "../withCookiebot";
import withGoogleTagManager from "../withGoogleTagManager";
import withToasts from "../withToasts";
import clientTheme from "../../utils/clientTheme";
import withRouteMetaData from "../withRouteMetaData";

interface RouteLoaderProps {
  route: React.ComponentType;
  routeMetaData?: RouteMetaData;
}

const RouteLoader: React.FC<RouteLoaderProps> = ({ route, routeMetaData }) => {
  const initialState = {
    ...window.__SETTINGS__,
    ...window.__INITIAL_DATA__,
  } as Partial<ConfigValues>;

  const messages = window.__I18N__;

  const theme = clientTheme(initialState?.client?.appId);

  const ConfiguredRoute = withConfig(
    withRouteMetaData(
      withCookiebot(
        withI18N(
          withBrandNeue(
            withTheme(
              withToasts(withGoogleTagManager(withTracking(route))),
              theme,
            ),
          ),
          messages,
        ),
      ),
      routeMetaData,
    ),
    initialState,
  );

  return (
    <JotaiProvider>
      <ConfiguredRoute />
    </JotaiProvider>
  );
};

export default RouteLoader;
